<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input style="width: 200px" clearable v-model.trim="searchData.title" size="mini" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="title" label="名称" min-width="150"> </el-table-column>
      <el-table-column prop="cover" label="封面" min-width="120">
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 4px; display: block"
            :src="row.cover"
            :preview-src-list="[row.cover]"
            fit="cover"
          >
            <div slot="error" style="line-height: 60px; text-align: center; background: #f5f7fa">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="activity_rules" label="规则" min-width="300">
        <template slot-scope="{ row }">
          <el-tag style="margin-right: 5px" v-for="(item, i) in row.activity_rules" :key="i" type="primary" size="mini">{{ item.title }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="150"> </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="900px">
      <el-form :model="formData" ref="form" label-width="90px" size="mini">
        <el-form-item label="名称：" prop="title" :rules="rules">
          <el-input style="width: 250px" clearable v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="封面：" prop="cover" class="cover" :rules="rules">
          <UploaderImg :url.sync="formData.cover"></UploaderImg>
        </el-form-item>
        <el-form-item label="规则：" class="rules">
          <el-button type="primary" size="mini" plain icon="el-icon-plus" @click="addRule">添加规则</el-button>
          <div class="box" v-if="formData.activity_rules.length > 0">
            <div class="row">
              <span class="col-1">序号</span>
              <span class="col-2">内容</span>
              <span class="col-3">操作</span>
            </div>
            <div class="row" v-for="(item, i) in formData.activity_rules" :key="i">
              <span class="col-1">{{ i + 1 }}</span>
              <span class="col-2">
                <el-input v-model="item.title" placeholder="请输入" clearable></el-input>
              </span>
              <span class="col-3">
                <i class="el-icon-error del-btn" @click="delRule(i)"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="邀请奖励：">
          <el-button type="primary" size="mini" plain icon="el-icon-plus" @click="addShare">添加奖励</el-button>
          <div class="share-box" v-if="formData.share_join_coupons.length > 0">
            <div class="row">
              <div class="col-1">序号</div>
              <div class="col-2">邀请人数</div>
              <div class="col-3">优惠券</div>
              <div class="col-4">操作</div>
            </div>
            <div class="row" v-for="(item, index) in formData.share_join_coupons" :key="index">
              <div class="col-1">{{ index + 1 }}</div>
              <div class="col-2">
                <el-input-number v-model="item.share_user_num" :step="1" :min="1" step-strictly></el-input-number>
              </div>
              <div class="col-3">
                <div class="coupon-item" v-for="(coupon, i) in item.coupon_list" :key="i">
                  <span class="coupon-title" style="width: 200px; text-align: right" @click="selectCoupon(index, i)">
                    {{ coupon.coupon_title || '点击选择优惠券' }}
                  </span>
                  <span style="margin: 0 5px">*</span>
                  <el-input-number v-model="coupon.coupon_num" :step="1" size="mini" :min="1" step-strictly></el-input-number>
                  <span style="margin: 0 5px">张</span>
                  <span>
                    <i class="el-icon-error del-btn" @click="delCoupon(index, i)"></i>
                  </span>
                  <span>
                    <i class="el-icon-circle-plus add-btn" @click="addCoupon(index, i)"></i>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <el-button type="danger" size="mini" @click="removeShare(index)">删除</el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="show_update = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
    <el-drawer title="优惠券列表" size="800px" :visible.sync="show_coupon" direction="rtl">
      <el-table :data="coupon_list">
        <el-table-column prop="id" label="" width="width">
          <template slot-scope="{ row }">
            <el-radio v-model="select_coupon" :disabled="row.status == 10" :label="row.id" @input="confirmCoupon(row)">{{ '' }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="120"> </el-table-column>
        <el-table-column prop="type" label="类型" min-width="100">
          <template slot-scope="{ row }">
            <el-tag v-if="row.type == 1" type="primary" size="small">满减券</el-tag>
            <el-tag v-if="row.type == 2" type="success" size="small">折扣券</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="内容" min-width="150">
          <template slot-scope="{ row }">
            <div v-if="row.type == 1" type="primary" size="small">满{{ row.full_money }}减{{ row.reduce_money }}</div>
            <div v-if="row.type == 2" type="success" size="small">{{ row.discount }}折</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100">
          <template slot-scope="{ row }">
            <el-tag v-if="row.status == 10" type="danger" size="small">{{ row.status_dsc }}</el-tag>
            <el-tag v-if="row.status == 20" type="primary" size="small">{{ row.status_dsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="有效期" min-width="230">
          <template slot-scope="{ row }">
            <div v-if="row.valid_type == 1">
              <div>开始时间：{{ row.start_time }}</div>
              <div>结束时间：{{ row.end_time }}</div>
            </div>
            <div v-if="row.valid_type == 2">有效天数：{{ row.valid_day }}天</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="coupon_total" :page.sync="coupon_data.page" :limit.sync="coupon_data.page_size" @pagination="getCouponList" />
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, addAPI, editAPI, getCouponListAPI } from './api'

import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { UploaderImg },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      show_coupon: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        title: ''
      },
      total: 0,
      formData: {
        title: '邀请好友领红包',
        cover: 'www.baidu.com/xxx.png',
        activity_rules: [],
        share_join_coupons: [
          {
            share_user_num: 1,
            coupon_list: [
              {
                coupon_id: 1,
                coupun_title: '2元折扣券',
                coupon_num: 1
              }
            ]
          }
        ]
      },
      index: '',
      i: '',
      select_coupon: '',
      coupon_list: [],
      coupon_total: 0,
      coupon_data: {
        page: 1,
        page_size: 10,
        status: 20
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
    this.getCouponList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async getCouponList() {
      const res = await getCouponListAPI(this.coupon_data)
      this.coupon_list = res.data || []
      this.coupon_total = res.total || 0
    },

    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getList()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.show_update = false
              this.getList()
            })
          }
        }
      })
    },
    //  add
    handleAdd() {
      this.formData = {
        title: '',
        cover: '',
        activity_rules: [],
        share_join_coupons: []
      }
      this.show_update = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 添加规则
    addRule() {
      this.formData.activity_rules.push({ title: '' })
    },
    // 删除一天规则
    delRule(i) {
      this.formData.activity_rules.splice(i, 1)
    },
    // 添加奖励
    addShare() {
      const data = {
        share_user_num: '',
        coupon_list: [
          {
            coupon_id: '',
            coupon_num: 1,
            coupon_title: ''
          }
        ]
      }
      this.formData.share_join_coupons.push(data)
    },
    // 删除一条奖励
    removeShare(index) {
      this.formData.share_join_coupons.splice(index, 1)
    },
    // 添加一个优惠券
    addCoupon(index, i) {
      this.formData.share_join_coupons[index].coupon_list.splice(i + 1, 0, {
        coupon_id: '',
        coupon_num: 1,
        coupon_title: ''
      })
    },
    // 删除一个优惠券
    delCoupon(index, i) {
      if (this.formData.share_join_coupons[index].coupon_list.length <= 1) return this.$message.warning('优惠券不能为空')
      this.formData.share_join_coupons[index].coupon_list.splice(i, 1)
    },

    // 打开优惠券弹窗
    selectCoupon(index, i) {
      this.index = index
      this.i = i
      this.select_coupon = this.formData.share_join_coupons[index].coupon_list[i].coupon_id
      this.show_coupon = true
    },
    // 确认选择优惠券
    confirmCoupon(e) {
      this.formData.share_join_coupons[this.index].coupon_list[this.i].coupon_id = e.id
      this.formData.share_join_coupons[this.index].coupon_list[this.i].coupon_title = e.name
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .rules {
    .box {
      background: #f5f5f5;
      border-radius: 5px;
      width: 700px;
      margin-top: 10px;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        .col-1 {
          text-align: center;
          width: 80px;
        }
        .col-2 {
          flex: 1;
          text-align: center;
          padding: 0 10px;
        }
        .col-3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          .del-btn {
            cursor: pointer;
            font-size: 20px;
            color: #f56c6c;
            &:hover {
              color: #f78989;
            }
          }
        }
      }
      .tip {
        text-align: center;
        padding: 10px;
        color: #909399;
        font-size: 14px;
      }
    }
  }
  .share-box {
    background: #f5f5f5;

    margin-top: 10px;
    border-radius: 5px;
    .row {
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dcdfe6;
      &:last-child {
        border: none;
      }
      .col-1 {
        width: 80px;
        text-align: center;
      }
      .col-2 {
        width: 120px;
        text-align: center;
      }
      .col-3 {
        flex: 1;
        text-align: center;
        padding: 0 10px;
        .coupon-item {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 0;
          .add-btn {
            cursor: pointer;
            font-size: 18px;
            color: #409eff;
            &:hover {
              color: #66b1ff;
            }
          }
          .del-btn {
            cursor: pointer;
            font-size: 18px;
            color: #f56c6c;
            margin-right: 5px;
            &:hover {
              color: #f78989;
            }
          }
          .coupon-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            color: #409eff;
          }
        }
      }
      .col-4 {
        width: 80px;
        text-align: center;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.cover /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>
