import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/company/activity/shareJoin/list`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/company/activity/shareJoin/del`,
    method: 'post',
    data: { id }
  })
}
export function addAPI(data) {
  return http({
    url: `/api/company/activity/shareJoin/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/company/activity/shareJoin/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/company/activity/shareJoin/detail`,
    method: 'get',
    params: { id }
  })
}
export function getCouponListAPI(params) {
  return http({
    url: `/api/company/coupon/list`,
    method: 'get',
    params
  })
}
